import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card,Button, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Header, BlockLoader} from '../../elements';

import AddProduct from './AddProduct';

class Products extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			showLoader: false,
			showAddModal: false,
		}
	}
	
	
	
	render(){
		
		
		return(
			<React.Fragment>
				
				<BlockLoader loading={this.state.showLoader} />
				
				<Row>
					<Col md={3} > <h5>Products</h5> </Col>
					<Col md={9} >
						<div className="text-right" >
							<button type="button" className="btn btn-outline-secondary btn-sm" onClick={(e)=>{this.setState({showAddModal: true})}} >
								<i className="ion-plus" /> New Product
							</button>
						</div>
					</Col>
					<Col md={12}> <hr className="mb-0 mt-10" /> </Col>
				</Row>
				
				
				<Modal show={this.state.showAddModal} className="modal right" size="md" >
					<Modal.Header closeButton >
						<Modal.Title >
							New Product
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					
						<AddProduct />
					
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}
	
}
export default Products;
