import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import LOGO_DARK from "../../../assets/images/wisdom-school-theme.png";
import Menu from "../Menu";
import TopHeader from "../TopHeader";
import Announcements from "../Announcements"; 
import {getls} from '../../../utils';
import LOGO from '../../../assets/images/wisdom-logo.png';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
		
		this.handleWindowScroll = this.handleWindowScroll.bind(this);
	}
	
	componentDidMount(){
		 window.addEventListener('scroll', this.handleWindowScroll);
	}
	
	handleWindowScroll(){
		
		if ($(window).scrollTop() > 50) {
			$('#header').addClass('header-scrolled');
		} else {
			$('#header').removeClass('header-scrolled');
		}
	}
	
	render(){
		
		
		$('html, body').animate({scrollTop:0}, 'slow');
		
		return(
			
		<React.Fragment>	
			
			<TopHeader />
			<div className="bg-top navbar-light">
				<div className="container">
				  <div className="row no-gutters d-flex align-items-center align-items-stretch" style={{textAlign: "center;"}}>
					<div className="col-md-4 d-flex align-items-center py-4">
						<div className="col-md d-flex topper align-items-center align-items-stretch py-md-4">
						  <div className="icon d-flex justify-content-center align-items-center"><span className="icon-phone2"></span></div>
						  <div className="text">
							<span>Call</span>
							<span>{getls('site', 'phone')}</span>
						  </div>
						</div>
					</div>
					<div className="col-md-4 d-flex align-items-center py-4 text-center">
					  <Link className="navbar-brand" to="/" >
						<img src={LOGO} alt="Wisdom Matric Hr.Sec.School" style={{width: "45%"}} />
					  </Link>
					</div>
					<div className="col-md-4 d-flex align-items-center py-4 plmd20">
					  <div className="row d-flex">
						<div className="col-md d-flex topper align-items-center align-items-stretch py-md-4">
						  <div className="icon d-flex justify-content-center align-items-center"><span className="icon-paper-plane"></span></div>
						  <div className="text">
							<span>Email</span>
							<span>{getls('site', 'email')}</span>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
				<div className="container d-flex align-items-center px-4" style={{padding: "0px !important;"}}>
				  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav"
					aria-expanded="false" aria-label="Toggle navigation">
					<span className="oi oi-menu"></span> Menu
				  </button>
				  <form action="#" className="searchform order-lg-last">
					
				  </form>
				  <div className="collapse navbar-collapse" id="ftco-nav">
					
					<Menu />
					
				  </div>
				</div>
			  </nav>
			 
			  <div className="" style={{lineHeight: "0;"}}>
			  <Announcements/>
			</div>	  
			</React.Fragment>  
		);
	}
	
}
export default Header;
