import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';


class Announcements extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			 announcementList: [],
				
		}
		this.loadAnnouncements = this.loadAnnouncements.bind(this);
	}
	
	componentDidMount(){
		this.loadAnnouncements();
	}
	
	loadAnnouncements(){
		try{
			
			var form = new FormData();
			form.append('type', 'announcement');
			form.append('expired', '1');
			
			API.post('site/v1/news/list', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({announcementList:res['data'].data, showLoader:false});
				}
				else{
					this.setState({showLoader:false});
				}
				
			});
			
		}
		catch(error){
			
		}
		
	}
	
	getContent(item){
		var rv=[];
		if(item.link =='' || item.link == null || item.link == undefined){
			rv.push(item.content);
		}
		else{
			rv.push(
			<a href={item.link} target="_blank">
				{item.content}
			</a>
			);
		}
		return rv;
		
	}
	
	render(){
		return(
				
				<marquee  className="announcement-scroll" direction="left">
				
				<ul>
				{this.state.announcementList.map((item,index)=>{
					return <li>{item.title}</li>;
			
				})	
				}
				</ul>		
				</marquee>
		
		);
	}
	
}
export default Announcements;
