import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import SliderPlaceholder from './SliderPlaceholder';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';

class Slider extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
			imageList: [],
		}
		
		this.loadSliders = this.loadSliders.bind(this);
	}
	
	componentDidMount(){
		this.loadSliders();
	}
	
	
	loadSliders(){
		try{
			
			API.get('site/v1/sliders').then(res=>{
				if(res['data'].status=='1'){
					this.setState({imageList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
		}
	}
	
	render(){
		
		if(this.state.showLoader){
			return(
				<SliderPlaceholder />
			);
		}
		
		return(
			<Carousel>
			{this.state.imageList.map((item,index)=>{
				return <Carousel.Item>
						<img
							className="d-block w-100"
							src={Api.ImageUrl + item.url}
							alt="First slide"
						/>
				</Carousel.Item>;
			})
			}
				
			</Carousel>
		); 
		
	}
	
}
export default Slider;
