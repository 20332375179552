import React, { Component } from 'react';
import {Redirect, Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Api} from '../../../utils/Variables';
import {upperCase, groupByMultiple} from '../../../utils';

class Sidebar extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
	}
	
	componentDidMount(){
		
	}
	
	
	render(){
		
		
		return(
			  <div>
			 
				
		</div>
						
		);
	}
	
}
export default Sidebar;
