import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import ReadMore from "./ReadMore";
import ViewAll from "./ViewAll";
import API from "../../../../utils/API";
import {momentDate} from '../../../../utils';
import {Api} from "../../../../utils/Variables";
import NOIMAGE from '../../../../assets/images/noimage_post.jpg';
import NOIMAGE_2 from '../../../../assets/images/noimage_post_2.jpg';

class Events extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader : true,
			eventList : [],
			
		}
		this.loadEvents = this.loadEvents.bind(this);
	}
	
	
	componentDidMount(){
		this.loadEvents();
	}
	
	loadEvents(){
		try{
			
			var form = new FormData();
			form.append ('type' , 'upcoming');
			form.append ('limit', '3');
			
			API.post('site/v1/news/list', form).then(res=>{
				if(res['data'].status=='1'){
						this.setState({eventList:res['data'].data, showLoader:false});
					}
				else{
					this.setState({showLoader:false});
				}	
			});
		}
		catch(error){
			
		}
	}

	
	getImage(item){
		try{
			
			if(item.featureimage==undefined || item.featureimage==null || item.featureimage=='')
				return NOIMAGE_2;
			else
				return Api.ImageUrl + item.featureimage;
		}
		catch(error){
			return NOIMAGE_2;
		}
	}
	
	render(){
		
		
		return(
			<>
			 <section className="ftco-section bg-light">
				<div className="container">
				  <div className="row justify-content-center mb-5 pb-2">
					<div className="col-md-8 text-center heading-section ">
					  <h2 className="mb-4"><span>Events &</span> Reports</h2>
					  <p>Here are our latest events & reports are highlighted</p>
					</div>
				  </div>
				  
				  <div className="row">
				  
					
					{this.state.eventList.map((item,index)=>{
						var img = this.getImage(item);
								return <div className="col-md-6 col-lg-4" >
								  <div className="blog-entry">
									<Link to={"/event/"+item.slug} className="block-20 d-flex align-items-end" style={{backgroundImage: `url(${img})`}} >
									  <div className="meta-date text-center p-2">
										<span className="day">{momentDate(item.eventstartdate,'DD')}</span>
										<span className="mos">{momentDate(item.eventstartdate,'MMM')}</span>
										<span className="yr">{momentDate(item.eventstartdate,'YYYY')}</span>
									  </div>
									</Link>
									<div className="text bg-white p-4">
									  <h3 className="heading text-center">
										<Link to={"/event/"+item.slug}  >{item.title}</Link>
									  </h3>
									  <div className="text-center">
										<p className="mb-0" >
											<Link to={"/event/"+item.slug} className="btn btn-primary">
												Read More <span className="ion-ios-arrow-round-forward"></span>
											</Link>
										</p>
									  </div>
									</div>
								  </div>
							</div>;
						})	
						}	
						
				  </div>
				</div>
			  </section>

			</>			
		);
	}
	
}
export default Events;
