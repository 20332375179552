import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Header, BlockLoader, PageHeader} from '../../elements';

class Pages extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
		}
	}
	
	
	getContent(fieldName){
		try{
			
			var d = this.props.data;	
			return 	d[fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	render(){
		
		
		return(
			<>
				<PageHeader 
					title={this.getContent('pagetitle')}  
					menu={this.getContent('pagetitle')}
				/>
				
				<section>
					<div className="container py-30" >
					
							<div dangerouslySetInnerHTML={{__html: this.getContent('pagecontent')}} />
							
					</div>
				</section>
			</>
		);
	}
	
}
export default Pages;
