import React, {Component} from 'react';
import './index.css';

class PageLoader extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			
		}
	}
	
	
	render(){
		 if(!this.props.loading)
		   {
			   return( '' );
		   }
		return(
			<div className="cl__loader__spinner">
			  <div className="cl__loader__bounce1"></div>
			  <div className="cl__loader__bounce2"></div>
			  <div className="cl__loader__bounce3"></div>
			</div>
		);
	}
}
export default PageLoader;
