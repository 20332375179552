import React, { Component } from 'react';
import {Redirect, NavLink, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";


class PageHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	render(){		
		
		return(
			<section className="banner-area" style={{borderBottom: '1px solid #f1f1f1'}} >
				<div className="container">
					<div className="row banner-content">
						<div className="col-lg-12 d-flex align-items-center justify-content-between">
							<div className="left-part">
								<h1>
									{this.props.title}
								</h1>
								<p>
									{this.props.content} 
								</p>
							</div>
							<div className="right-part">
								<Link to="/" >Home</Link>
								<span className="fa fa-caret-right"></span>
								<a href="javascript:;">{this.props.menu}</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		);
	}
	
}
export default PageHeader;
