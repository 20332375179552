import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Products from './pages/Products';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Gallery from './pages/Gallery';
import GalleryList from './pages/Gallery/GalleryList';
import SingleEvent from './pages/Events/SingleEvent';

const pageList = [
	
	{ name: 'Home', path: '/', component: Home },
	{ name: 'Gallery', path: '/gallery', component: Gallery },
	{ name: 'Gallery List', path: '/gallery/:slug', component: GalleryList },
	{ name: 'Event', path: '/event/:slug', component: SingleEvent },
	{ name: 'Not Found', path: '*', component: NotFound },
	
	
];
export default pageList;