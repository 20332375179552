import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import routes from '../views';
import {Header,Footer} from '../views/elements';
import API from '../utils/API';
import {Api} from '../utils/Variables';
import {setls} from '../utils';



class SiteLayout extends Component{
	constructor(props){
		super(props);
		this.state = {
			siteData: [],
		}
	}
	
	componentDidMount(){
		this.loadSettings();
	}
	
	loadSettings(){
		try{
			API.get('v1/settings/me/1').then(res=>{
				if(res['data']. status == '1'){
					this.setState({siteData: res['data'].data});
					setls('site',res['data'].data);	
				}
			});
		}
		catch(error){
			
		}
	
	}
	
	render(){
			
		return(
			<div> 
			
				<Header />
				
				<div className="section" style={{ minHeight: '300px'}} >
						<Switch>
							{routes.map((page,key)=>(
								<Route exact path={page.path}  component={page.component} key={key} />
							))}
						</Switch>	
				</div>
				
				<Footer />
			</div>
		);
	}
	
}
export default SiteLayout;