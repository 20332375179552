import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";


class ViewAll extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}	
	}
	
	render(){
		return(
			<div className="row d-flex justify-content-center">
				<div className="col-lg-3">
					<div className="section-title text-center">
						<div className="buttons gray flex-row d-flex">
							<i className="fa fa-star" aria-hidden="true"></i>
							<div className="desc">
								<a href="#">
									<p>
										<span>View All</span> 
										<br/>
										Events & Posts
									</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>		
		);
	}
	
}
export default ViewAll;
