import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import LOGO_DARK from "../../../assets/images/siva-nursery-primary-school-logo-white.png";
import Menu from "../Menu";


class TopHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
		
	}
	
	
	
	render(){
		
		
		return(
			
		<React.Fragment>	
			<div className="col-md-12 top-bar">
				<div className="container">
				<div className="row">
					<div className="col-md-6 socials">
					<a href="https://www.facebook.com/wisdomschoolkadayanallur/" target="_blank" >
						<i className="ion-social-facebook"></i>
					</a>&emsp;<a href="#"><i className="ion-social-twitter"></i></a>
					</div>
					<div className="col-md-6 buttons">
					<Link to="/contact" className="btn btn-info">Contact us</Link>
					</div>
				</div> 
				</div> 
			</div> 
			
			
	</React.Fragment>
	
	
		);
	}
	
}
export default TopHeader;
