import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";



class ReadMore extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	render(){
		return(
			<div className="">
				<h3>{this.props.title}
					<Link to={"/event/"+this.props.slug} style={{float: "right", fontSize: "12px"}} >Read More &raquo;</Link></h3>
				
				<a href="javascript:;" className="title">
					
				</a>
			</div>				
			
		);
	}
	
}
export default ReadMore;
