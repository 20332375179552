import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import footerLogo from "../../../assets/images/wisdom-school-theme.png";
import CopyRight from "./CopyRight";
import {getls} from '../../../utils';


class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<React.Fragment>
					
				<footer className="ftco-footer ftco-bg-dark ftco-section">
					<div className="container">
					  <div className="row">
						<div className="col-md-6 col-lg-4">
						  <div className="ftco-footer-widget">
							<h2 className="ftco-heading-2">About us</h2>
							<img src={footerLogo}  style={{width: "100%", marginBottom: "15px"}} />
							<div className="block-23 mb-3">
							  <ul>
								<li><span className="icon icon-map-marker"></span><span className="text">{getls('site','address')}</span></li>
								<li><span className="icon icon-phone"></span><span className="text">{getls('site','mobile')} {getls('site','phone')}</span></li>
								<li><span className="icon icon-envelope"></span><span className="text">{getls('site','email')}</span></li>
							  </ul>
							</div>
						  </div>
						</div>
						<div className="col-md-6 col-lg-4" style={{overflowX: "hidden"}}>
						  <div className="ftco-footer-widget ml-md-4">
							<h2 className="ftco-heading-2">Links</h2>
							<ul className="list-unstyled">
							  <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span>Home</a></li>
							  <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span>About</a></li>
							  <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span>Services</a></li>
							  <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span>Deparments</a></li>
							  <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span>Contact</a></li>
							</ul>
						  </div>
						</div>
						<div className="col-md-6 col-lg-4">
						  <div className="ftco-footer-widget">

							<h2 className="ftco-heading-2">Follow us</h2>
							
							<div className="fb-page" data-href="https://www.facebook.com/wisdomschoolkadayanallur/" data-tabs="timeline" data-width="" data-height="250" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/wisdomschoolkadayanallur/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/wisdomschoolkadayanallur/">Wisdom Matriculation Higher Secondary School</a></blockquote></div>
							
						  </div>
						</div>

					  </div>
					  <div className="row">
						<div className="col-md-12 text-center">

						  <CopyRight />
						</div>
					  </div>
					</div>
			  </footer>
			</React.Fragment>
		);
	}
	
}
export default Footer;
