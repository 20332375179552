
const COOKIE_KEY = 'wis37';

const Api = {
	Url :'https://wisdomschool.edu.in/webadmin/services/api/',
	ImageUrl: 'https://wisdomschool.edu.in/webadmin/services', //Dont put slash in last
	AcademicPath: ''
};

export {
	COOKIE_KEY,
	Api,
};
