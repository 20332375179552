import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';
import SiteLayout from '../layouts/SiteLayout';
import Login from '../views/pages/Login';

export default () => (
	
<BrowserRouter base="/" >
	<Switch>
		<Route path="/" component={SiteLayout} />
	</Switch>
</BrowserRouter >
);