import React from 'react';
import ReactDOM from 'react-dom';
import 'jquery-confirm';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import './assets/ionicons/css/ionicons.min.css';
import './assets/css/open-iconic-bootstrap.min.css';
import './assets/icomoon/icomoon.css';
import './assets/flaticon/flaticon.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/custom.css';


import App from './App';

import './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

