import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import footerLogo from "../../../assets/images/wisdom-school-theme.png";

 
class CopyRight extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<p>
				Copyright &copy;
				{new Date().getFullYear()} All Rights Reserved. &copy; 2019 | Design By : 
				<a href="http://peacesoft.in/" title="Peace Soft Technologies, Tirunelveli" target="_blank"> Peace Soft</a>
			</p>
		);
	}
	
}
export default CopyRight;
