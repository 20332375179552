import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Header, BlockLoader, PageHeader, PageLoader} from '../../elements';
import API from '../../../utils/API';
import Pages from '../Pages';


class NotFound extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			showLoader: true,
			pageUrl: '',
			pageData: [],
		}
	}
	
	componentDidMount(){

		this.state.showLoader = true;
		this.state.pageUrl = this.props.match.params[0].replace(/^\/|\/$/g, '');

		this.loadPageContent();
	}

	componentWillReceiveProps(props){

		this.state.pageUrl = props.match.params[0].replace(/^\/|\/$/g, '');

		this.loadPageContent();
		//this.forceUpdate();
	}
	
	loadPageContent(){
		try{
			this.setState({showLoader: true, pageData: []});
			var url = this.props.match;
			API.get('site/v1/page/'+this.state.pageUrl).then(res=>{
				if(res['data'].status=='1'){
					this.setState({showLoader: false, pageData: res['data'].data});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
		}
	}
	
	
	render(){
		
		 return(
			<React.Fragment>
				{this.state.showLoader ? <PageLoader loading={true}  />
					: Object.keys(this.state.pageData).length>0 ?
						<Pages data={this.state.pageData} />
					: <PageHeader title="Not Found" content="Sorry the requested page not found.!" menu="404" />
				}				
			</React.Fragment>
		);
	}
	
}
export default NotFound;
