import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import API from '../../../utils/API';

class Announcement extends Component{
	constructor(props){
		super(props);
		this.state = {
			announcementList: [],
				
		}
		this.loadAnnouncements = this.loadAnnouncements.bind(this);
	}
	
	componentDidMount(){
		this.loadAnnouncements();
	}
	
	loadAnnouncements(){
		try{
			
			var form = new FormData();
			form.append('type', 'announcement');
			form.append('expired', '1');
			
			API.post('site/v1/news/list', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({announcementList:res['data'].data, showLoader:false});
				}
				else{
					this.setState({showLoader:false});
				}
				
			});
			
		}
		catch(error){
			
		}
		
	}
	
	getContent(item){
		var rv=[];
		if(item.link =='' || item.link == null || item.link == undefined){
			rv.push(item.content);
		}
		else{
			rv.push(
			<a href={item.link} target="_blank">
				{item.content}
			</a>
			);
		}
		return rv;
		
	}
	
	
	
	render(){
		
		
		return(				
			<div>
			<h4 style={{color: '#fff', border: '1px solid #4c4f65', padding: '10px'}} >Announcement</h4>
			<marquee  className="" direction="up" style={{minHeight: '350px', color: '#fff'}} >
			
				<ul>
					{this.state.announcementList.map((item,index)=>{
						return <div dangerouslySetInnerHTML={{ __html: this.getContent(item) }}></div>;
				
					})	
					}
				</ul>		
			</marquee>	
			</div>			
		);
	}
	
}
export default Announcement;
