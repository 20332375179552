import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import './index.css';

class SliderPlaceholder extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<div className="cl__slider__placeholder__container" >
				<div className="cl__slider__placeholder__loader" >
					<div className="cl__slider__loader"></div>
				</div>
			</div>
		);
	}
	
}
export default SliderPlaceholder;
