import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";


class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
		}
	}
	
	
	
	render(){
		
		
		return(
			<React.Fragment>
				<div className="container" >			
					<Row className="mt-50" >
						<Col md={{span: 4, offset: 4}} >
							<Card bg="light"  >
								<Card.Body >
									<Card.Title>User Login</Card.Title>
									<hr />
									<form action="" method="post" encType="multipart/form-data" id="frmLogin" >
										
										<Row>
											<Col md={12} >
												<label>Username <span className="text-danger">*</span> </label>
												<input type="text" className="form-control form-control-sm" placeholder="Username" name="i__userName" required  />
											</Col>
										</Row>
										
										<Row className="mt-15" >
											<Col md={12} >
												<label>Password  <span className="text-danger">*</span> </label>
												<input type="password" className="form-control form-control-sm" placeholder="Password" name="i__password" required />
											</Col>
										</Row>
										
										<Row className="mt-15" >
											<Col md={12} >
												<button type="submit" className="btn btn-info btn-md btn-block" disabled={this.state.loginText!='Login'} >
													{this.state.loginText}
													{this.state.loginText!='Login' ? <i className="ion-loading-a" /> : null}
												</button>
											</Col>
										</Row>
									
									</form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		);
	}
	
}
export default Login;
