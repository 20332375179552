import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Slider, Announcements} from "../../elements";
import StudentsCount from "./StudentsCount"; 
import HighLights from "./HighLights"; 
import Events from "./Events";
import About from "./About"; 
import DigitalClassRooms from "./DigitalClassRooms"; 
import Announcement from './Announcement';


class Home extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<React.Fragment>
				
			<div className="container" >
				<div className="row" >
					<div className="col-md-4" >
						<div className="secondary-section">
						
							<Announcement />
							
						</div>
					</div>
					<div className="col-md-8" >
						<Slider/>	
					</div>
				</div>
			</div>
				
			
			<About />
			
			<DigitalClassRooms />
			
			<Events />
			
			</React.Fragment>
		);
	}
	
}
export default Home;
