import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Header, BlockLoader} from '../../elements';

class Dashboard extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
		}
	}
	
	
	render(){
		
		
		return(
			<React.Fragment>
				
				<BlockLoader loading={false} />
				
				This is Dashboard 
				
			</React.Fragment>
		);
	}
	
}
export default Dashboard;
