import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import MenuPlaceholder from './MenuPlaceholder';
import {removeBothSlash} from '../../../utils';
import './index.css';

class Menu extends Component{
	constructor(props){
		super(props);
		this.state = {
			menuData: [],
			showLoader: true,
		}
	}
	
	componentDidMount(){
		this.loadMenus();
		$("li.dropdown").hover(function(){
			alert('ji');
		});
		/* $('nav .dropdown').hover(function(){
			var $this = $('nav .dropdown');
			// 	 timer;
			// clearTimeout(timer);
			$this.addClass('show');
			$this.find('> a').attr('aria-expanded', true);
			// $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
			$this.find('.dropdown-menu').addClass('show');
		}, function(){
			var $this = $('nav .dropdown');
				// timer;
			// timer = setTimeout(function(){
				$this.removeClass('show');
				$this.find('> a').attr('aria-expanded', false);
				// $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
				$this.find('.dropdown-menu').removeClass('show');
			// }, 100);
		}); */
	}
	
	componentWillMount(){
		
	}
	
	loadMenus(){
		try{
			API.get('v1/menus/1').then(res=>{
				if(res['data']. status == 1){
					this.setState({menuData: res['data'].data, showLoader: false});
				}
					this.setState({showLoader: false});
			
			});
		}
		catch(error){
			
		}
	}
	
	
	getFourthLevel(menus){
		
		var rv = [];
		menus.map((item,i)=>{			
			rv.push(
				<li className="">
					<NavLink exact to={'/'+removeBothSlash(item.menulink)} className="nav-link" >{item.title} </NavLink>
				</li>
			);
		});
		return rv;	
	}
	
	getThridLevel(menus){
		var rv = [];
		menus.map((item,i)=>{
			
			var child = this.getFourthLevel(item.children);
			child && child.length>0 ?
			rv.push(
				<li className="nav-item dropdown">
					<a  href="javascript:;" className="nav-link" >{item.title} </a>
					<ul className="dropdown-menu dropdown-content" >
						{child}
					</ul>
				</li>
			)
			:
			rv.push(
				<li className="">
					<NavLink exact to={'/'+removeBothSlash(item.menulink)} className="nav-link"  >{item.title} </NavLink>
				</li>
			);
			
		});
		return rv;		
	}
	  
	getSecondLevel(menus){
		var rv = [];
		menus.map((item,i)=>{
			
			var child = this.getThridLevel(item.children);
			child && child.length>0 ?
			rv.push(
				<li className="nav-item dropdown">
					<a  href="javascript:;" className="nav-link" >{item.title} </a>
					<ul className="dropdown-menu dropdown-content" >
						{child}
					</ul>
				</li>
			)
			:
			rv.push(
				<li className="">
					<NavLink exact to={'/'+removeBothSlash(item.menulink)} className="nav-link"  >{item.title} </NavLink>
				</li>
			);
			
		});
		return rv;		
	}
	  
	getMenuTopItem(menus){
		
		var rv = [];
		menus.map((item,i)=>{
			var child = this.getSecondLevel(item.children);
			child && child.length>0 ?
			rv.push(
				<li className="nav-item dropdown">
					<a  href="javascript:;" className="nav-link" >{item.title} </a>
					<span className="caret"></span>
					<ul className="dropdown-menu dropdown-content" >
					{child}
					</ul>
				</li>
			)
			:
			rv.push(
				<li className="nav-item">
					<NavLink exact to={'/'+removeBothSlash(item.menulink)} className="nav-link"  >{item.title} </NavLink>
				</li>
			);
			
		});
		return rv;
	}
	
	
	render(){
		
		
		return(		
			<ul className="navbar-nav mr-auto nav">
				<li className="nav-item"><NavLink to="/" className="nav-link" >Home</NavLink></li>
				{this.getMenuTopItem(this.state.menuData)}
			</ul>
		);
	}
	
}
export default Menu;
