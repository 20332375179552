import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Header, BlockLoader, PageHeader, CircleLoader} from '../../elements';
import API from '../../../utils/API';
import {Api} from '../../../utils/Variables';
import {getCookieField, getAscSortOrder} from '../../../utils';
import './index.css';


const styles = {
	imgContainer: {
		border: '2px solid #8e8b8b',
		padding: '7px',
		borderRadius: '15px',
		cursor: 'pointer',
	}
}

class Gallery extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			dataList: [],
			showLoader: true,
		}
	}
	
	
	componentDidMount(){
		this.loadGallery();
	}
	
	loadGallery(){
		try{
			this.setState({showLoader: true});
			API.get('site/v1/gallery/').then(res=>{
				if(res['data'].status=='1') {
					this.setState({dataList: res['data'].data, showLoader: false});
				}
				else{
					this.setState({showLoader: false});
				}
			});						
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	showGalery(){
		try{
			var rv = [];
			this.state.dataList.map((item,i)=>{
				rv.push(<Col md={3} className="mt-15" >
					<div style={styles.imgContainer} >
						<Link to={"/gallery/"+ item.slug} >
							<div>
								<div className="text-center" >
									<img src={Api.ImageUrl + item.filepath} style={{width: '100%'}} />
									<h5>{item.gallerytitle}</h5>
								</div>
							</div>
						</Link>
					</div>
				</Col>);
			});
			return rv;
		}
		catch(error){ }
	}
	
	
	render(){
		
		
		return(
			<>
				<PageHeader 
					title= "Gallery"  
					menu="Gallery" 
				/>
				
				<section>
					<div className="container py-30" >
							
							{this.state.showLoader ? <Row>
								<Col md={12} >
									<div className="text-center mb-50" >
										<CircleLoader loading={true} />
									</div>
								</Col>
							</Row> : this.showGalery()}
							
					</div>
				</section>
			</>
		);
	}
	
}
export default Gallery;
