import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";



class HighLights extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	render(){
		
		return(
			<section className="feature-area">
				<div className="">
					<div className="row">
						<div className="col-lg-4 col-md-6 coll-1">
							<div className="single-feature coll-11">
								<a href="#" className="title">
									
									<h3>HIGHLIGHTS</h3>
								</a>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 coll-2">
							<div className="single-feature coll-22">
								<a href="#" className="title">
									
									<h3>FACILITIES</h3>
								</a>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 coll-3">
							<div className="single-feature coll-33">
								<a href="#" className="title">
									
									<h3>ACHIEVEMENTS</h3>
								</a>
							</div>
						</div>

					</div>
				</div>
			</section>
		);
	}
	
}
export default HighLights;
