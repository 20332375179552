import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";



class StudentsCount extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			<section className="fact-area">
				<div className="container">
					<div className="fact-box">
						<div className="row align-items-center">
							<div className="col single-fact">
								<h2>10K+</h2>
								<p>Total Students</p>
							</div>
							<div className="col single-fact">
								<h2>120</h2>
								<p>Teaching Staffs</p>
							</div>
							<div className="col single-fact">
								<h2>30</h2>
								<p>Non-Teaching Staffs</p>
							</div>
							<div className="col single-fact">
								<h2>50</h2>
								<p>Class Rooms</p>
							</div>
							<div className="col single-fact">
								<h2>33</h2>
								<p>School Buses</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
	
}
export default StudentsCount;
