import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import BG from '../../../assets/images/bg_3.jpg';
import DIGITAL from '../../../assets/images/digital.jpg';


class DigitalClassRooms extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	render(){
		
		return(
			<section className="ftco-section ftco-counter img" id="section-counter" style={{backgroundImage: `url(${BG})`}}>
			<div className="container">
			  <div className="row justify-content-center mb-5 pb-2 d-flex">
				<div className="col-md-6 align-items-stretch d-flex">
				  <div className="img img-video d-flex align-items-center" >
					<div className="video justify-content-center">
					  <iframe width="560" height="315" src="https://www.youtube.com/embed/1-ZT566QWdo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						<span className="ion-ios-play"></span>
					</div>
				  </div>
				</div>
				 <div className="col-md-6 heading-section heading-section-white  pl-lg-5 pt-md-0 pt-5">
				  <h2 className="mb-4">OUR SALIENT FEATURES</h2>
					  <ul style={{color: 'white'}}>
							<li>Well established eco friendly environment</li>
							<li>Well Constructed Mosque in our campus for Prayer</li>
							<li>RO facility for drinking water</li>
							<li>Special coaching classes for NEET, JEE, Islamic Studies and Life Skills.</li>
								<li>NEET foundation course from V std to X  std with free of cost</li>
								<li>Easily approachable Administrative Management</li>
								<li>Buses for all areas with GPS facility</li>
								<li>Fully furnished and equipped laboratories for Science Subjects</li>
								<li>Creative teaching through Smart Class</li>
								<li>More number of female teachers to take care of your girl child</li>
								<li>Well established Computer Lab</li>
								<li>Learning with understanding through Montessori way of teaching.</li> 
								<li>Special coaching classes for Yoga, Silambam…</li>
								<li>Well experienced teachers</li>
								<li>CCTV facility to monitor the activities of the students in the campus</li>
								<li>Fee Concession will be given to the students who score high marks in the SSLC Examination and economically backward students</li> 
								<li>Well maintained toilet facilities</li>
					  </ul>
				</div>
			  </div>
			</div>
		  </section>
		);
	}
	
}
export default DigitalClassRooms;
