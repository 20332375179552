import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card,Button, Modal} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {BlockLoader} from '../../elements';

class AddProduct extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			showLoader: false,
		}
	}
	
	
	
	render(){
		
		
		return(
			<div>
				<form action="" method="post" encType="multipart/form-data" id="frmAddProduct" >
					
					<Row>
						<Col md={3} >
							<label>Code </label>
						</Col>
						<Col md={9} >
							<input type="text" size="sm" className="form-control form-control-sm" required />
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} >
							<label>Name </label>
						</Col>
						<Col md={9} >
							<input type="text" size="sm" className="form-control form-control-sm" required />
						</Col>
					</Row>
				
				</form>
			</div>
		);
	}
	
}
export default AddProduct;
