import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col, Carousel} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import BG from '../../../assets/images/ab.jpg';


class About extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	render(){
		
		return(
				<div className="container">
				  <div className="row d-flex">
					<div className="col-md-5 order-md-last wrap-about wrap-about d-flex align-items-stretch">
					  <div className="img" style={{backgroundImage: `url(${BG})`, backgroundSize: "contain"}}></div>
					</div>
					<div className="col-md-7 wrap-about py-5 pr-md-4">
					  <h2 className="mb-4">About the School</h2>
					  <p>Wisdom school was founded in the year 2000, with the aim of educating the students around the predominant rural area of  kadayanallur. Our challenge is to establish a superior quality English medium matriculation school adhering to urban standards in a Tamil dominated locality.</p>
					  <div className="row mt-5">
						<div className="col-lg-12">
						  <div className="services-2 d-flex">
							<div className="icon mt-2 d-flex justify-content-center align-items-center"><span className="flaticon-security"></span></div>
							<div className="text pl-3">
							  <h3>Mission</h3>
							  <p>Our vision is to forge strong, positive connections with students, so they can achieve independence, build confidence, and gain academic knowledge. We aim to develop well-rounded and thoughtful students. We strive to support this environment by building a more inclusive curriculum, addressing a range of learning styles
								We are determined to create an atmosphere where values are considered as achievement. Our school prepares students to understand, contribute to and succeed in a rapidly changing society and thus make the world a better place.</p>
							</div>
						  </div>
						</div>
						<div className="col-lg-12">
						  <div className="services-2 d-flex">
							<div className="icon mt-2 d-flex justify-content-center align-items-center"><span className="flaticon-reading"></span></div>
							<div className="text pl-3">
							  <h3>Vision</h3>
							  <p>“I like the dreams of the future better than the history of the past” – Thomas Jefferson.
							  The future depends on the dreams of today and Wisdom Matric School has a vision to march on towards perfection, molding the precious young minds, igniting their potentiality and thereby creating a more honest, peaceful, successful and harmonious society</p>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
		);
	}
	
}
export default About;
