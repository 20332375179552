import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import API from '../../../utils/API';
import './index.css';

class MenuPlaceholder extends Component{
	constructor(props){
		super(props);
		this.state = {
			menuData: [],
			showLoader: true,
		}
	}
	
	render(){
		return(
			<div className="cl__menu__spinner">
			  <div className="cl__menu__rect1"></div>
			  <div className="cl__menu__rect2"></div>
			  <div className="cl__menu__rect3"></div>
			  <div className="cl__menu__rect4"></div>
			  <div className="cl__menu__rect5"></div>
			</div>
		);
	}
	
}
export default MenuPlaceholder;
